import React, { Suspense } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
// import Header from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './AddLiquidity/redirects'
import MigrateV1 from './MigrateV1'
import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
// import LP_Farm from './LP_Farm'
// import Staking from './Staking'
// import Voting from './Voting'
// import Proposal from './Proposal'
// import VotingProposal from './Voting/voting-proposal'

// import Farm from './Farm'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
// import Transaction from '../components/Transactions'
// import CandleGraph from '../mypages/CandleGraph'
// import SideNavbar from "../components/SideNavbar"
// import Main from '../Home/pages/Main'
// import Privacy from '../Home/pages/PrivacyPolicy'
// import Terms from '../Home/pages/Terms'
import { Toaster } from 'react-hot-toast'

// const AppWrapper = styled.div`
//  // display: flex;
//  // flex-flow: column;
//   //align-items: flex-start;
//  // overflow-x: hidden;
//  // min-height: 100vh;
// `
// const HeaderWrapper = styled.div`
//   ${({ theme }) => theme.flexRowNoWrap}
//   width: 100%;
//   justify-content: space-between;
// `
// const BodyWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   //width: 1480px;
//   //margin: 0 auto;  
//   // flex: 1;
//   padding-top: 10px; 
//   overflow-y: auto;
//   overflow-x: hidden;
//   z-index: 10;
//   margin-top: 60px;
//  // justify-content: space-evenly;
//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//       padding: 4px;
//   `};

//   z-index: 1;
//   // display: flex;
//   // flex-direction: column;
//   // align-items: center;
//   // justify-content: center;
//   // min-height: 864px;
// `
const NewWrapper = styled.div`
   display: flex;
  // flex-direction: row;
  // width: 100%;
  // padding-top: 60px;
  // flex: 1;
  // overflow-y: auto;
  // overflow-x: hidden;
  // z-index: 10;
  // justify-content: space-evenly;
  // ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  //   padding: 16px;
`};

  z-index: 1;
`

// const ChartWrapper = styled.div`
//   width: 80%;
//   margin-right: 20px;
//   // border: 1px solid #363a45;
// `
// const Marginer = styled.div`
//   margin-top: 5rem;
// `

export default function App() {
  return (
    <Suspense fallback={null}>
      <HashRouter>
        <Route component={GoogleAnalyticsReporter} />
        {/* <AppWrapper id='mainwrapper'>
        <HeaderWrapper>
            <Header />
        </HeaderWrapper> */}
        {/* <div className='Main-div'> */}
        {/* <div className='sidebar-wrapper'>
            <SideNavbar />
         
          </div> */}
        {/* <div className='content-wrapper'> */}
        {/* <BodyWrapper id="bodywrapper"> */}
        <Popups />
        <NewWrapper className='content-body' id="mainwrapper--">
          {/* <ChartWrapper id="chart">
                <CandleGraph />
              </ChartWrapper> */}
          <Web3ReactManager>
            <Switch>
              {/* <Route exact strict path="/" component={Main} /> */}
              <Route exact strict path="/swap" component={Swap} />
              {/*<Route exact strict path="/farm" component={Farm} />*/}
              <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
              <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
              <Route exact strict path="/find" component={PoolFinder} />
              <Route exact strict path="/pool" component={Pool} />
              {/* <Route exact strict path="/lp-farm" component={LP_Farm} />
              <Route exact strict path="/staking" component={Staking} /> */}
              {/* <Route exact strict path="/privacy" component={Privacy} />
              <Route exact strict path="/terms" component={Terms} /> */}


              {/* <Route exact strict path="/proposal" component={Proposal} />
              <Route exact strict path="/voting" component={Voting} />
              <Route exact strict path="/voting-proposal" component={VotingProposal} /> */}


              <Route exact strict path="/create" component={RedirectToAddLiquidity} />
              <Route exact path="/add" component={AddLiquidity} />
              <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
              <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
              <Route exact strict path="/remove/v1/:address" component={RemoveV1Exchange} />
              <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
              <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
              <Route exact strict path="/migrate/v1" component={MigrateV1} />
              <Route exact strict path="/migrate/v1/:address" component={MigrateV1Exchange} />
              <Route component={RedirectPathToSwapOnly} />
            </Switch>
          </Web3ReactManager>
        </NewWrapper>
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
        />
        
        {/* <LogoTitle src={LogoH} alt="bg" /> 2011835011050*/}
        {/* <Marginer /> */}
        {/* <Transaction /> */}
        {/* </BodyWrapper> */}
        {/* </div> */}
        {/* </div>         */}
        {/* </AppWrapper> */}
      </HashRouter>
    </Suspense>

  )
}
