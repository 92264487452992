import React, { useCallback, useState } from 'react'
import { isMobile } from 'react-device-detect'
// import { Text } from 'rebass'

import styled from 'styled-components'
import Settings from '../Settings'
// import useHtPrice from '../../hooks/useHtPrice'
// import LngSwithForWeb from './LngSwithForWeb'
// import Nav from './Nav'
import { useIsDarkMode } from '../../state/user/hooks'
import Logo from './Logo'
import menuIcon from '../../assets/images/msic-menu.svg'
import MobileMenu from '../MobileMenu'
import AccountButton from './AccountButton'
import Web3Status from '../Web3Status'
import TranslatedText from 'components/TranslatedText'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  background:  ${({ theme }) => theme.colors.bg1}
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: calc(100%);
    position: relative;
  `};
`
const Menu = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
    width: 22px;
    margin-right: 8px;
    img {
      width: 100%;
      display: block;
    }
  `};
`
const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 320px) {
    width: 20%;
  }
`

const HeaderControlsMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  `};
`

const StyledTopBarInner = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 24px;
  //background: linear-gradient(to right,#2e1850,#7752b1);
  background: #fff;
  position: fixed;
  color: #fff;
  //border-bottom: 1px solid #492e6b;
  @media (max-width: 850px) {
    min-width: auto;
  }
  border-bottom: 1px solid #ff3636;
`
const StyledAccountButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    .lng-switch, .price {
      display: none;
    }
  `};
`
// const Price = styled.div`
//   color: ${props => props.theme.colors.primary};
//   margin-right: 24px;
//   font-weight: bolder;
// `

// function isZero(num: number) {
//   return Math.abs(num - 0) <= 1e-8
// }
const StyledAbsoluteLink = styled.a`
  position: relative;
  color: #fff;
  margin: 24px;
  text-decoration: none;
  // &:hover {
  //   //color: ${({ theme }) => theme.colors.primary};
  //   background-image: linear-gradient(to bottom, #2d0c47, #260d47, #1e0e47, #150f47, #080f46);
  //   color:#fff;
  // }
  @media (max-width: 400px) {
    margin-right: 24px;
  }
`
export default function Header() {
  const isDark = useIsDarkMode()
  // const { pippiPrice } = useHtPrice()
  const [mobileMenu, setMobileMenu] = useState(false)
  const handlePresentMobileMenu = useCallback(() => {
    setMobileMenu(true)
  }, [setMobileMenu])
  const handleDismissMobileMenu = useCallback(() => {
    setMobileMenu(false)
  }, [setMobileMenu])
  return (
    <HeaderFrame>
      <StyledTopBarInner>
        <HeaderElement>
          <Menu onClick={handlePresentMobileMenu}>
            <img src={menuIcon} alt="menu" />
          </Menu>
          
          <Title href="/" id="logostyle">
            <Logo isDark={isDark} />
          </Title>
          {/* {!isMobile && <Nav />} */}
        </HeaderElement>
        {isMobile && (
          <HeaderControlsMobile>
            <AccountButton />
            <Settings />
          </HeaderControlsMobile>
        )}
        {!isMobile && (
          <HeaderControls>
              <StyledAbsoluteLink href="#swap">
              {/* <img src={iconexchange} style={{height:"20px", width:"30px", position: 'relative', top: '4px'}} /> */}
              <TranslatedText translationId={198}>Swap</TranslatedText>
            </StyledAbsoluteLink>
            <StyledAbsoluteLink href="#/add/ETH">
              {/* <img src={iconliquidity} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> */}
              <TranslatedText translationId={200}>Liquidity</TranslatedText>
            </StyledAbsoluteLink>
            <StyledAbsoluteLink href="#pool">
              {/* <img src={iconpool} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> */}
              <TranslatedText translationId={200}>Pools</TranslatedText>
            </StyledAbsoluteLink>
            <StyledAbsoluteLink href="https://explorer.dotblox.io/" target="_blank">
            {/* <img src={iconpool} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> */}
            <TranslatedText translationId={200}>Explorer</TranslatedText>
            </StyledAbsoluteLink>
            <StyledAbsoluteLink href="https://staking.dotblox.io/" target="_blank">
              {/* <img src={iconstake} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> */}
              <TranslatedText translationId={200}>Staking</TranslatedText>
            </StyledAbsoluteLink>
            <StyledAbsoluteLink href="https://xtring.network/" target="_blank">
              {/* <img src={iconpool} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> */}
              <TranslatedText translationId={200}>Crosschain</TranslatedText>
            </StyledAbsoluteLink>
            <StyledAbsoluteLink href="https://createtoken.dotblox.io/" target="_blank">
              {/* <img src={iconpool} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> */}
              <TranslatedText translationId={200}>Create Token</TranslatedText>
            </StyledAbsoluteLink>
            <StyledAbsoluteLink href="https://dtbx.exchange/" target="_blank">
              {/* <img src={iconpool} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> */}
              <TranslatedText translationId={200}>Exchange</TranslatedText>
            </StyledAbsoluteLink>
            <StyledAbsoluteLink href="https://status.dotblox.io/" target="_blank">
              {/* <img src={iconpool} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> */}
              <TranslatedText translationId={200}>Status</TranslatedText>
            </StyledAbsoluteLink>
            <HeaderElement>
              <StyledAccountButtonWrapper>
                <Web3Status />
                {/* {!isZero(pippiPrice) && <Price className="number price">1PUD=${pippiPrice.toFixed(3)}</Price>} */}
                <AccountButton />
                <Settings />
                {/* <LngSwithForWeb /> */}
              </StyledAccountButtonWrapper>
            </HeaderElement>
          </HeaderControls>
        )}
        <MobileMenu onDismiss={handleDismissMobileMenu} visible={mobileMenu} />
      </StyledTopBarInner>
    </HeaderFrame>
  )
}
