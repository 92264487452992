import React from 'react'
import styled from 'styled-components'
const logoDark = require('../../../assets/images/dotblox.png')
const logoLight = require('../../../assets/images/dotblox.png')
// import logoText from '../../../assets/images/logo_text.png'÷\

interface LogoProps {
  isDark: boolean
}
const Logo: React.FC<LogoProps> = ({ isDark }) => {
  return (
    <StyledLogo>
      <img src={false? logoDark : logoLight} alt="logo" style={{height:"45px"}} />
      {/* <div className="text">CalculasSwap</div> */}
    </StyledLogo>
  )
}

const StyledLogo = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-right: 25px;
  text-decoration: none;
  
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    .text {
      display: none
    }
  `};
`

export default Logo
