import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  width: 100%;
  // max-width: 420px;
  padding: 1rem;
  margin-top: 10px;
`

export const Container = styled.div`
  position: relative;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper id="swapwrapper">{children}</BodyWrapper>
}
