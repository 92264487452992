import styled from 'styled-components'

export const Topcard = styled.div`
  border-radius: 21.2px;
  padding: 1.2px;
  border-radius: 20px;
  margin-bottom: 12px;
  width: 100%;
  // background-image: linear-gradient(246deg,#da2eef 7.97%,#2b6aff 49.17%,#39d0d8 92.1%);
  // background-image: linear-gradient(var(--gradient-rotate,246deg),#da2eef 7.97%,#2b6aff 49.17%,#39d0d8 92.1%);
`
export const Top2Card = styled.div`
  border-radius: 20px;
  // background-image: linear-gradient(140.14deg,rgba(0,182,191,.15),rgba(27,22,89,.1) 86.61%),linear-gradient(321.82deg,#18134d,#1b1659);
  background-color: #ffffffa6;
  padding: 1rem;
`
export const DisplayFlexBT = styled.div`
  display: flex;
  justify-content: space-between;
`